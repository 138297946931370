import React from 'react';
import { Link } from 'gatsby';
import Button from 'reusecore/src/elements/Button';
import { GridContainer, SolutionWrapper } from './solutionsbanner.style';
import FeaturedSolution from './FeaturedSolution';

const SubFeaturedSolution = ({ solutions = [] }) => {
  if (solutions.length === 0) {
    return null;
  }
  if (solutions.length === 1) {
    return <FeaturedSolution solution={solutions[0]} />;
  }

  return (
    <GridContainer template="1fr 1fr">
      {solutions.map(solution => {
        const {
          excerpt,
          frontmatter: { slug, title, cover }
        } = solution;
        return (
          <SolutionWrapper featured cover={cover} key={`${slug}-${title}`}>
            <div className="product-info">
              <Link to={`/soluciones/${slug}`}>
                <h4>{title}</h4>
              </Link>
              <p>{excerpt}</p>
            </div>
            <div className="product-nav">
              <Link to={`/soluciones/${slug}`}>
                <Button title="Ver Más" variant="outlined" />
              </Link>
            </div>
          </SolutionWrapper>
        );
      })}
    </GridContainer>
  );
};

export default SubFeaturedSolution;

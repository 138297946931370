import React from 'react';
import { Link } from 'gatsby';
import Button from 'reusecore/src/elements/Button';
import ProductsBannerWrapper, { GridContainer, SolutionWrapper } from './solutionsbanner.style';
import FeaturedSolution from './FeaturedSolution';
import SubFeaturedSolutions from './SubFeaturedSolutions';

const SolutionsBanner = ({ heading, solutions = [] }) => {
  if (solutions.length < 1) {
    return null;
  }
  const featuredSolutions = solutions.slice(0, 3);

  return (
    <ProductsBannerWrapper>
      <GridContainer template="70% auto">
        <div className="inner-heading">
          <h2>Soluciones</h2>
          <p>
            Descubre algunas de nuestras soluciones para
            {` ${heading}`}
          </p>
        </div>
        <div className="inner-nav">
          <Link to="/soluciones">
            Todas las soluciones
            {' '}
            <i className="flaticon-next" />
          </Link>
        </div>
      </GridContainer>
      {solutions.length === 1 && <FeaturedSolution solution={solutions[0]} />}
      {solutions.length === 2 && <SubFeaturedSolutions solutions={solutions} />}
      {solutions.length > 2 && (
        <GridContainer template={['2fr 1fr 1fr', '1fr 1fr']}>
          {featuredSolutions.map((solution, index) => {
            const isFirst = index === 0;
            const {
              excerpt,
              frontmatter: { title, cover, slug }
            } = solution;
            return (
              <SolutionWrapper
                featured={isFirst}
                fullWidth={!isFirst}
                cover={cover}
                key={`/soluciones/${slug}`}
              >
                <div className="product-info">
                  <Link to={`/soluciones/${slug}`}>
                    <h4>{title}</h4>
                  </Link>
                  <p>{excerpt}</p>
                </div>
                <div className="product-nav">
                  <Link to={`/soluciones/${slug}`}>
                    <Button title="Ver más" variant="outlined" />
                  </Link>
                </div>
              </SolutionWrapper>
            );
          })}
        </GridContainer>
      )}
    </ProductsBannerWrapper>
  );
};

export default SolutionsBanner;

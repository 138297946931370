import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
// import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
// import Button from 'reusecore/src/elements/Button';
import BannerWrapper from './bannerSection.style';

import FeatureBlock from '../../components/FeatureBlock';
import Container from '../../components/UI/Container';

const BannerSection = ({ row, col, cover, title, subtitle }) => {
  return (
    <BannerWrapper id="banner_section" cover={cover}>
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={<Heading as="h1" content={title} />}
              description={(
                <Fragment>
                  <Text content={subtitle} />
                </Fragment>
)}
            />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center'
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '60%', '65%']
  }
};

export default BannerSection;

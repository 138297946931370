import styled, { css, keyframes } from 'styled-components';

const CatalogCategoryBodyWrapper = styled.div`
  color: #5d646d;
  line-height: 2;
  margin-top: 50px;
  margin-bottom: 80px;
  h1,
  h2,
  h3,
  h4 {
    color: ${({ theme }) => theme.colors.headingColor};
  }
  ul {
    li {
      list-style-type: inherit;
      margin-left: 20px;
    }
  }

  .grid-container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 15px;
    @media only screen and (max-width: 667px) {
      display: block;
      margin-bottom: 15px;
    }
  }
  .info-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    padding: 25px 30px;
    h2,
    h3,
    h4 {
      margin: 0;
      margin-top: 10px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.headingColor};
    }
    @media only screen and (max-width: 667px) {
      text-align: center;
      display: block;
      margin-bottom: 15px;
    }
  }
  .info-card {
    line-height: 1;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    transition: all 0.3s;
    &:hover {
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
    }
    margin-bottom: 15px;
    ${'' /* max-width: 400px; */}
    .info-background {
      height: 100%;
      background-color: ${({ theme }) => theme.colors.background};
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      .img-wrapper {
        height: 250px;
        img {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          height: 100%;
          width: 100%;
          display: block;
          object-fit: cover;
          border: 0;
        }
      }
      .txt-wrapper {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 15px 30px;
        min-height: 250px;
        h2,
        h3,
        h4 {
          margin: 0;
          margin-top: 10px;
          font-weight: 400;
          color: ${({ theme }) => theme.colors.headingColor};
        }
        h2 {
          font-size: 1.8rem;
        }
        h3,
        h4 {
          font-size: 1.5rem;
        }
      }
    }
    p {
      margin-top: 20px;
      line-height: 1.5;
    }
  }

  .btn {
    &.primary {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background-color: ${({ theme }) => theme.colors.primary};
      min-height: 46px;
      min-width: 48px;
      border-radius: 4px;
      font-family: inherit;
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      text-transform: capitalize;
      padding: 10px 20px;
      border: 0;
      transition: all 0.3s ease;
      box-sizing: border-box;
      height: 40px;
      &:hover {
        box-shadow: ${({ theme }) => theme.colors.primaryBoxShadow};
      }
    }
  }
`;

export const ProductListWrapper = styled.div`
  padding-bottom: 2rem;
  .inner-heading {
    h2 {
      color: ${({ theme }) => theme.colors.headingColor};
      font-size: 30px;
      line-height: 35px;
      font-weight: 900;
      margin: 0;
      margin-bottom: 5px;
    }
    p {
      color: ${({ theme }) => theme.colors.labelColor};
      margin-bottom: 10px;
      font-size: 1rem;
      line-height: 20px;
      font-weight: 300;
    }
  }
  .inner-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media only screen and (max-width: 667px) {
      justify-content: inherit;
    }
    a {
      color: ${({ theme }) => theme.colors.headingColor};
      text-decoration: none;
      font-weight: 900;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const ProductItemWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  position: relative;
  padding: 0 1.875rem;
  margin: 1rem 3rem;
  min-height: 250px;
  z-index: 1;
  border-radius: 3px;
  border-bottom: 3px solid ${({ theme }) => theme.colors.inactiveIcon};
  ${'' /* background-color: ${({ theme }) => theme.colors.background}; */}
  transition: 0.2s all;
  ${({ featured }) =>
    featured &&
    css`
      background-color: transparent;
      padding: 0;
      @media only screen and (min-width: 668px) and (max-width: 980px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    `}
  @media only screen and (max-width: 667px) {
    margin-top: 20px;
    padding: 0 1rem;
    margin: 1rem 1rem;
  }
  @media only screen and (max-width: 572px) {
    margin-top: 20px;
    padding: 0 0rem;
    margin: 1rem 0rem;
  }
  .product-img {
    height: 85%;
    width: 30%;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      object-fit: contain;
      max-height: 325px;
      ${'' /* box-shadow: ${({ theme }) => theme.colors.greyBoxShadow}; */}
    }
    @media only screen and (max-width: 667px) {
      width: 100%;
      height: inherit;
    }
  }
  .product-info-group {
    width: 70%;
    padding: 1.5rem;
    @media only screen and (max-width: 667px) {
      width: 100%;
    }
  }
  .product-tags {
    margin-bottom: 5px;
    color: ${({ theme }) => theme.colors.labelColor};
    span {
      font-size: 10px;
      line-height: 15px;
      font-weight: 700;
      text-transform: uppercase;
      &.divider {
        margin: 0 6px;
      }
      &.secondary {
        color: ${({ theme }) => theme.colors.orange};
      }
    }
  }
  .product-info {
    h3,
    h4 {
      color: ${({ theme }) => theme.colors.textColor};
      font-size: 20px;
      line-height: 25px;
      font-weight: 900;
      margin: 0;
      margin-bottom: 5px;
    }
    p {
      color: ${({ theme }) => theme.colors.textColor};
      margin-bottom: 10px;
      font-size: 1rem;
      line-height: 20px;
      font-weight: 300;
    }
  }

  ${({ height }) =>
    height &&
    css`
      min-height: ${height};
    `}

  &:hover {
    border-bottom: 3px solid ${({ theme }) => theme.colors.lightBlue};
    h3,
    h4 {
      color: ${({ theme }) => theme.colors.lightBlue};
    }
  }
`;

const progress = keyframes`
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
`;

export const ProgressLine = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  transition: 0.2s all;
  ${'' /* width: 100%; */}
  animation: ${progress} ${({ loop }) => loop || 2500}ms linear infinite;
  background-color: ${({ theme }) => theme.colors.lightBlue};
`;

export const LoopIcon = styled.div`
  position: absolute;
  top: 20px;
  left: 25px;
  color: ${({ active, theme }) => (active ? theme.colors.lightBlue : theme.colors.labelColor)};
`;

export const Pill = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  border-radius: 20px;
  padding: 5px 15px;
  background-color: ${({ theme }) => theme.colors.background};
  font-size: 14px;
  font-weight: 500;
  ${'' /* color: ${({ theme }) => theme.colors.lightBlue}; */}
  a {
    color: ${({ theme }) => theme.colors.textColor};
  }
  &:hover {
    a {
      color: ${({ theme }) => theme.colors.link};
    }
  }
  @media (max-width: 767px) {
    padding: 7px 15px;
  }
  @media (max-width: 767px) {
    font-size: 13px;
    padding: 7px 15px;
  }
  @media (max-width: 480px) {
    ${'' /* font-size: 7px; */}
    padding: 5px 10px;
    span {
      font-size: 12px;
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  ${({ template }) => {
    if (typeof template === 'string') {
      return css`
        grid-template-columns: ${template};
      `;
    }
    return css`
      grid-template-columns: ${template[0]};
      @media only screen and (min-width: 668px) and (max-width: 980px) {
        grid-template-columns: ${template[1]};
        grid-template-rows: ${template[1]};
      }
    `;
  }}}
  ${({ row }) =>
    row &&
    css`
      margin-top: 0px;
      grid-auto-flow: row;
    `}
  @media only screen and (max-width: 667px) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`;

export default CatalogCategoryBodyWrapper;

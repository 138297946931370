import React from 'react';
import { ProductListWrapper, GridContainer } from './catalogcategory.style';
import ProductItem from './ProductItem';

const ProductList = ({ products = [] }) => {
  return (
    <ProductListWrapper>
      <GridContainer template="auto">
        <div className="inner-heading">
          <h2>Productos</h2>
          <p>
            Revise algunas soluciones destacadas y descubra cómo las ciudades pueden volverse cada
            vez más sustentables, inteligentes y sostenibles.
          </p>
        </div>
      </GridContainer>

      {products.map(product => {
        return (
          product && (
            <ProductItem key={`sp-product-${product.frontmatter.slug}`} product={product} />
          )
        );
      })}
    </ProductListWrapper>
  );
};

export default ProductList;

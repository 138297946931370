import React from 'react';
import Container from 'common/source/components/UI/Container';
// import ProductsBanner from 'common/source/containers/ProductsBanner';
import CatalogSolutionsBanner from 'common/source/containers/CatalogSolutionsBanner';

import CatalogCategoryBodyWrapper from './catalogcategory.style';
import ProductList from './ProductList';

const CatalogCategoryBody = ({ node, products = [], solutions }) => {
  const { html, frontmatter } = node;
  return (
    <CatalogCategoryBodyWrapper>
      <Container>
        {products.length > 0 && <ProductList products={products} />}
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <CatalogSolutionsBanner heading={frontmatter.title} solutions={solutions} />
      </Container>
    </CatalogCategoryBodyWrapper>
  );
};

export default CatalogCategoryBody;

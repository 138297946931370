import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import { Icon } from 'react-icons-kit';
import { circle_plus } from 'react-icons-kit/ikons/circle_plus';
import { circle_play } from 'react-icons-kit/ikons/circle_play';
import {
  ProductItemWrapper,
  ProgressLine,
  LoopIcon,
  Pill
} from './catalogcategory.style';

const ImageCarousel = ({ thumbnail, slides = [], alt }) => {
  const isFirstEqual = thumbnail === slides[0];
  const images = isFirstEqual ? slides : [thumbnail, ...slides];
  const [ticks, setTicks] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const shouldIncrement = ticks < images.length - 1;
      if (shouldIncrement) {
        setTicks(tick => tick + 1);
      } else {
        setTicks(0);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [ticks]);
  return (
    <Fragment>
      <Image src={images[ticks] || thumbnail} alt={alt} />
      <ProgressLine loop={2000} />
    </Fragment>
  );
};

const ProductItem = ({ product }) => {
  const [active, setActive] = useState(false);
  const {
    excerpt,
    frontmatter: { slug, thumbnail, slides, loop, title, tags = [] }
  } = product;
  const onMouseEnter = () => setActive(true);
  const onMouseLeave = () => setActive(false);
  return (
    <ProductItemWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className="product-img">
        <Link to={`/product/${slug}`}>
          {active && loop ? (
            <ImageCarousel
              thumbnail={thumbnail}
              slides={slides}
              alt={`${title} from UpSolar`}
            />
          ) : (
            <Image src={thumbnail} alt={`${title} from UpSolar`} />
          )}
          {loop && (
            <LoopIcon active={active}>
              <Icon icon={active ? circle_play : circle_plus} size={24} />
            </LoopIcon>
          )}
        </Link>
      </div>
      <div className="product-info-group">
        <div className="product-tags">
          {tags.map((tag, index) => {
            const isLast = index === tags.length - 1;
            return (
              <Fragment key={`${slug}-${tag}`}>
                <span>{tag}</span>
                {!isLast && <span className="divider">|</span>}
              </Fragment>
            );
          })}
        </div>
        <div className="product-info">
          <Link to={`/product/${slug}`}>
            <h3>{title}</h3>
            <p>{excerpt}</p>
          </Link>
        </div>
      </div>
      {loop && (
        <Pill>
          <Link to={`/product/${slug}`}>+ Modelos</Link>
        </Pill>
      )}
    </ProductItemWrapper>
  );
};

export default ProductItem;
